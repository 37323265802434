import {makeAutoObservable} from "mobx";
import axios from "axios";
import {AdvInfo} from "./AdvInfoStore";


class AdvSnapshotStore {
    loading: boolean = false;
    advSnapshots: AdvSnapshot[] = [];

    constructor() {
        makeAutoObservable(this)
    }

    fetchSnapshotList = async (): Promise<AdvSnapshot[]> => {
        return this.advSnapshots = await advSnapshotApi.getSnapshotList()
    }

    updateSnapshots = async (): Promise<AdvSnapshot[]> => {
        return this.advSnapshots = await advSnapshotApi.createSnapshots()
    }

    sendTelegramStats = async (): Promise<AdvSnapshot[]> => {
        return this.advSnapshots = await advSnapshotApi.sendTelegramStats()
    }
}

export const advSnapshotStore: AdvSnapshotStore = new AdvSnapshotStore()


class AdvSnapshotApi {
    async getSnapshotList(): Promise<AdvSnapshot[]> {
        let response = await axios.get("/api/adv/snapshot");
        return response.data
    }
    async createSnapshots(): Promise<AdvSnapshot[]> {
        let response = await axios.post("/api/adv/snapshot");
        return response.data
    }

    async sendTelegramStats(): Promise<AdvSnapshot[]> {
        let response = await axios.post("/api/adv/telegram");
        return response.data
    }
}

export const advSnapshotApi: AdvSnapshotApi = new AdvSnapshotApi()

export type AdvSnapshot = {
    id: number;
    snapshotDate: Date;
    viewCount: number;
    likeCount: number;
    commentCount: number;
    advInfo: AdvInfo
}
