import React, {useEffect, useState} from "react";
import Row from "antd/es/grid/row";
import Col from "antd/es/grid/col";
import Card from "antd/es/card/Card";
import {DatePicker, Statistic, Typography} from "antd";
import {Line} from "@ant-design/charts";
import {AdvInfo, advInfoStore, AdvStats} from "../store/AdvInfoStore";
import {List} from "antd";
import {CommentOutlined, EyeOutlined, HeartOutlined, VideoCameraAddOutlined} from "@ant-design/icons";
import {CompanyMonthlyStats, productStore} from "../store/ProductStore";



export const DashboardPage = () => {
    const [currentMonth, setCurrentMonth] = useState<AdvStats>({
        snapshotDate: new Date(),
        comments: 0,
        views: 0,
        likes: 0
    });
    const [countAdvInCurrentMonth, setCountAdvInCurrentMonth] = useState<number>(0);
    const [countPrice, setCurrentPrice] = useState<number>(0);

    useEffect(() => {
        advInfoStore.getCurrentMonthStats().then((value) => setCurrentMonth(value));
        advInfoStore.getCurrentMonthPrice().then((value) => setCurrentPrice(value));
        advInfoStore.countAdvInCurrentMonth().then((value) => setCountAdvInCurrentMonth(value));
    },[])


    return (
        <Row gutter={[16, 16]} className="content-container">
            <Col span={24}><Typography.Title level={3}>Статистика за месяц</Typography.Title></Col>
            <Col span={6}><Card><Statistic title="Просмотры" value={currentMonth.views} prefix={<EyeOutlined />}/></Card></Col>
            <Col span={6}><Card><Statistic title="Лайки" value={currentMonth.likes} prefix={<HeartOutlined />}/></Card></Col>
            <Col span={6}><Card><Statistic title="Комменты" value={currentMonth.comments} prefix={<CommentOutlined />}/></Card></Col>
            <Col span={6}><Card><Statistic title="Роликов за месяц" value={countAdvInCurrentMonth + '/'  + countPrice} prefix={<VideoCameraAddOutlined />}/></Card></Col>

            <Col span={24}><LastMonthStats/></Col>
            <Col span={24}><CompanyMonthStats/></Col>
        </Row>
    )
}

const { RangePicker } = DatePicker;

export const LastMonthStats = () => {

    const [data, setData] = useState<AdvStats[]>([]);
    const [top10, setTop10] = useState<AdvInfo[]>([]);

    useEffect(() => {
        advInfoStore.getLast30Stats().then((value) => setData(value));
        advInfoStore.getTop10Adv().then(value => setTop10(value))
    },[])

    const config: any = {
        data,
        padding: 'auto',
        xField: 'snapshotDate',
        yField: 'views',
        // xAxis: {
        //     // type: 'timeCat',
        //     tickCount: 5,
        // },
        smooth: true,
    };

    return (
        <Card title={"Статистика по дням"} extra={[
            <RangePicker key="month_range"/>
        ]}>
            <Row gutter={16}>
                <Col span={18}>
                    <Line {...config} tooltip={{fields: ["views", "likes", "comments"]}}/>
                </Col>
                <Col span={6}>
                    <List
                        header={"Топ просмотров"}
                        itemLayout="horizontal"
                        dataSource={top10}
                        renderItem={(item: AdvInfo) => (
                            <List.Item>
                                <List.Item.Meta
                                    title={<a href={item.url}>{item.meta.name}</a>}
                                    description={item.meta.author}
                                />
                                <div>{item.viewCount}</div>
                            </List.Item>
                        )}
                    />
                </Col>
            </Row>
        </Card>
    )
}


export const CompanyMonthStats = () => {
    const [data, setData] = useState<CompanyMonthlyStats[]>([]);

    useEffect(() => {
        productStore.fetchCompanyStats().then((value) => setData(value));
    },[])

    const config: any = {
        data,
        padding: 'auto',
        xField: 'month',
        yField: 'viewCount',
        seriesField: 'company',
        // xAxis: {
        //     // type: 'timeCat',
        //     tickCount: 5,
        // },
        smooth: true,
    };

    return (
        <Card title={"Статистика по компаниям"}>
            <Row gutter={16}>
                <Col span={24}>
                    <Line {...config} tooltip={{fields: ["viewCount", "likeCount", "commentCount"]}}/>
                </Col>
            </Row>
        </Card>
    )
}