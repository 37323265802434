import {makeAutoObservable} from "mobx";
import axios from "axios";

class ProductStore {
    loading: boolean = false;
    productList: Product[] = [];

    constructor() {
        makeAutoObservable(this)
    }

    fetchProductList = async (): Promise<Product[]> => {
        try {
            this.loading = true
            return this.productList = await productApi.getProducts()
        } finally {
            this.loading = false
        }
    }

    saveProduct = async (product: Product): Promise<Product> => {
        let savedProduct = await productApi.saveProduct(product)
        await this.fetchProductList()
        return savedProduct;
    }

    deleteProduct = async (product: Product): Promise<void> => {
        await productApi.deleteProduct(product)
        await this.fetchProductList()
    }

    fetchProductStats = async () : Promise<ProductMonthlyStats[]> => {
        return await productApi.getProductStats()
    }

    fetchCompanyStats = async () : Promise<CompanyMonthlyStats[]> => {
        return await productApi.getCompanyStats()
    }

}

export const productStore: ProductStore = new ProductStore()

class ProductApi {
    async getProducts(): Promise<Product[]> {
        let response = await axios.get("/api/product/");
        return response.data
    }

    async saveProduct(product: Product): Promise<Product> {
        let response = await axios.post("/api/product/", product);
        return response.data
    }

    async deleteProduct(product: Product): Promise<Product> {
        let response = await axios.delete("/api/product/" + product.id);
        return response.data
    }

    async getCompanyStats(): Promise<CompanyMonthlyStats[]> {
        let response = await axios.get("/api/product/stats/byCompany");
        return response.data
    }

    async getProductStats(): Promise<ProductMonthlyStats[]> {
        let response = await axios.get("/api/product/stats/byProduct");
        return response.data
    }
}

export const productApi: ProductApi = new ProductApi()

export type Product = {
    id: number;
    name: string;
}

export type CompanyMonthlyStats = {
    company: string;
} & Stats

export type ProductMonthlyStats = {
    company: string;
    name: string;
} & Stats

export type Stats = {
    viewCount: string;
    likeCount: string;
    commentCount: string;
}
