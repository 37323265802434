import React, {FC, useEffect, useState} from "react";
import {observer} from "mobx-react";
import Row from "antd/es/grid/row";
import Col from "antd/es/grid/col";
import {Button} from "../components/Button";
import {Descriptions, Form, Input, PageHeader, Popconfirm, Select, Skeleton, Statistic, Table, Tag, Typography} from "antd";
import {ProfitStatistic} from "../components/ProfitStatistic";
import {toJS} from "mobx";
import {FormDrawer} from "../components/FormDrawerComponent";
import {AdvInfo, advInfoApi, advInfoStore, DailyStats} from "../store/AdvInfoStore";
import {advSnapshotStore} from "../store/AdvSnapshotStore";
import {ColumnsType} from "antd/es/table";
import {Product, productStore} from "../store/ProductStore";
import {DeleteOutlined, EyeOutlined} from "@ant-design/icons";
import {useParams} from "react-router-dom";
import {truncate} from "./IndexPage";

import type {ProColumns} from '@ant-design/pro-components';
import {EditableProTable, ProCard, ProFormField, ProFormRadio} from '@ant-design/pro-components';

const {Link} = Typography;


type RouteParams = { id: string }

export const AdvDetailsPage: FC = observer(() => {
    const {id} = useParams<RouteParams>() as RouteParams;

    const [loading, setLoading] = useState<boolean>(true);
    const [adv, setAdv] = useState<AdvInfo>();
    const [dailyStats, setDailyStats] = useState<DailyStats[]>([]);

    const columnsEdit: ProColumns<DailyStats>[] = [
        {
            title: 'Ид',
            dataIndex: 'id',
            readonly: true,
            width: '15%',
        },
        {
            title: 'Дата',
            dataIndex: 'snapshotDate',
            valueType: 'date',
        },
        {
            title: 'Просмотры',
            dataIndex: 'viewCount',
            valueType: 'digit',
        },
        {
            title: 'Лайки',
            dataIndex: 'likeCount',
            valueType: 'digit',
        },
        {
            title: 'Комменты',
            dataIndex: 'commentCount',
            tooltip: "Test",
            // proFieldProps: {
            //     emptyText: <span>emptyText</span>,
            //     label: <span>label</span>,
            // },
            valueType: 'digit',
        },
        {
            title: 'Действия',
            valueType: 'option',
            width: 250,
            render: (text, record, _, action) => [
                <a
                    key="editable"
                    onClick={() => {
                        action?.startEditable?.(record.id || 0);
                    }}
                >
                    Редактировать
                </a>,
                <a
                    key="delete"
                    onClick={() => {
                        // setDataSource(dataSource.filter((item) => item.id !== record.id));
                    }}
                >
                    Удалить
                </a>,
            ],
        },
    ];

    useEffect(() => {
        Promise.all([
            advInfoApi.getAdv(id),
            advInfoStore.findDailyStats(id)
        ]).then(([adv, stats]) => {
            setAdv(adv)
            setDailyStats(stats)
        }).finally(() => setLoading(false))
    }, [])

    return (
        <Skeleton loading={loading}>
            <PageHeader
                title={<Link href={adv?.url}>{truncate(adv?.meta.name, 50)}</Link>}
                ghost={false}
            >
                <Row>
                    <Descriptions size="small" column={2}>
                        <Descriptions.Item label="Автор">{adv?.meta.author}</Descriptions.Item>
                        <Descriptions.Item label="Продукт">
                            {adv?.products.map(adv => <Tag>{adv.name}</Tag>)}
                        </Descriptions.Item>
                        <Descriptions.Item label="Creation Time">{adv?.meta.create_date}</Descriptions.Item>
                        <Descriptions.Item label="Провайдер">{adv?.type}</Descriptions.Item>
                    </Descriptions>
                </Row>

                <Row>
                    <Statistic
                        title="Просмотры"
                        precision={0}
                        value={adv?.viewCount}
                    />
                    <Statistic title="Лайки"
                               value={adv?.likeCount}
                               style={{
                                   margin: '0 32px',
                               }}/>
                    <Statistic title="Кол-во стат"
                               value={dailyStats.length}
                               style={{
                                   margin: '0 32px',
                               }}/>
                </Row>
            </PageHeader>
            <Row className="content-container">
                <Col span={24}>
                    <ProCard>
                        <EditableProTable<DailyStats>
                            rowKey="id"
                            scroll={{
                                x: 960,
                            }}
                            recordCreatorProps={{
                                position: 'top',
                                creatorButtonText: <span>Добавить запись</span>,
                                record: () => {

                                    let date: string = new Date().toISOString().slice(0, 10)
                                    return {
                                        id: Math.random(),
                                        isNew: true,
                                        snapshotDate: date,
                                        viewCount: 0,
                                        likeCount: 0,
                                        commentCount: 0
                                    } as DailyStats;
                                },
                            }}

                            loading={false}
                            columns={columnsEdit}
                            request={async () => ({
                                data: dailyStats,
                                total: dailyStats.length + 10,
                                success: true,
                            })}
                            cardBordered={false}
                            value={dailyStats}
                            onChange={data => advInfoStore.findDailyStats(id).then(setDailyStats)}
                            editable={{
                                type: 'single',
                                onSave: async (rowKey, data, row) => {
                                    console.log(rowKey, data, row);
                                    await advInfoStore.saveDailyStats(id, data)
                                    console.log("Saved");
                                },
                            }}
                        />
                    </ProCard>

                </Col>
            </Row>
        </Skeleton>
    )
})