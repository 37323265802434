import React, {useEffect, useState} from "react";
import Row from "antd/es/grid/row";
import Col from "antd/es/grid/col";
import Card from "antd/es/card/Card";
import {DatePicker, Form, Input, Statistic, Typography} from "antd";
import {Line} from "@ant-design/charts";
import {AdvInfo, advInfoStore, AdvStats} from "../store/AdvInfoStore";
import {List} from "antd";
import {CommentOutlined, EyeOutlined, HeartOutlined, VideoCameraAddOutlined} from "@ant-design/icons";
import {CompanyMonthlyStats, productStore} from "../store/ProductStore";
import Space from "antd/es/space";
import UserOutlined from "@ant-design/icons/UserOutlined";
import LockOutlined from "@ant-design/icons/LockOutlined";
import {Button} from "../components/Button";
import Divider from "antd/es/divider";
import axios from "axios";



export const ChannelStatsPage = () => {
    const [data, setData] = useState<any>();

    return (
        <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={async ({url}) => {
                let {data} = await axios.post("/api/stats/youtube/", url)
                setData(data)
            }}
            autoComplete="off"
        >
            <Form.Item
                label="Youtube url"
                name="url"
                rules={[{ required: true, message: 'Please enter youtube link!' }]}
            >
                <Input />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit">
                    Submit
                </Button>
            </Form.Item>

            result: {JSON.stringify(data)}
        </Form>
    )
}

const { RangePicker } = DatePicker;

export const LastMonthStats = () => {

    const [data, setData] = useState<AdvStats[]>([]);
    const [top10, setTop10] = useState<AdvInfo[]>([]);

    useEffect(() => {
        advInfoStore.getLast30Stats().then((value) => setData(value));
        advInfoStore.getTop10Adv().then(value => setTop10(value))
    },[])

    const config: any = {
        data,
        padding: 'auto',
        xField: 'snapshotDate',
        yField: 'views',
        // xAxis: {
        //     // type: 'timeCat',
        //     tickCount: 5,
        // },
        smooth: true,
    };

    return (
        <Card title={"Статистика по дням"} extra={[
            <RangePicker key="month_range"/>
        ]}>
            <Row gutter={16}>
                <Col span={18}>
                    <Line {...config} tooltip={{fields: ["views", "likes", "comments"]}}/>
                </Col>
                <Col span={6}>
                    <List
                        header={"Топ просмотров"}
                        itemLayout="horizontal"
                        dataSource={top10}
                        renderItem={(item: AdvInfo) => (
                            <List.Item>
                                <List.Item.Meta
                                    title={<a href={item.url}>{item.meta.name}</a>}
                                    description={item.meta.author}
                                />
                                <div>{item.viewCount}</div>
                            </List.Item>
                        )}
                    />
                </Col>
            </Row>
        </Card>
    )
}


export const CompanyMonthStats = () => {
    const [data, setData] = useState<CompanyMonthlyStats[]>([]);

    useEffect(() => {
        productStore.fetchCompanyStats().then((value) => setData(value));
    },[])

    const config: any = {
        data,
        padding: 'auto',
        xField: 'month',
        yField: 'viewCount',
        seriesField: 'company',
        // xAxis: {
        //     // type: 'timeCat',
        //     tickCount: 5,
        // },
        smooth: true,
    };

    return (
        <Card title={"Статистика по компаниям"}>
            <Row gutter={16}>
                <Col span={24}>
                    <Line {...config} tooltip={{fields: ["viewCount", "likeCount", "commentCount"]}}/>
                </Col>
            </Row>
        </Card>
    )
}