import {makeAutoObservable} from "mobx";
import axios from "axios";
import {Product} from "./ProductStore";


class AdvInfoStore {
    loading: boolean = false;
    advInfoList: AdvInfo[] = [];

    lastStats: AdvStats[] = []
    top10Adv: AdvInfo[] = []

    todayStats: TodayStats = {
        viewCount: 0,
        todayCommentCount: 0,
        todayLikeCount: 0,
        todayViewCount: 0
    };

    constructor() {
        makeAutoObservable(this)
    }

    fetchAdvInfoList = async (): Promise<AdvInfo[]> => {
        this.loading = true;
        try {
            return this.advInfoList = await advInfoApi.getAdvInfoList()
        } finally {
            this.loading = false
        }
    }

    fetchAdvById = async (advId: string): Promise<AdvInfo> => {
        return await advInfoApi.getAdv(advId)
    }

    getTodayStats = async (): Promise<TodayStats> => {
        return this.todayStats = await advInfoApi.getTodayStats()
    }

    getLast30Stats = async (): Promise<AdvStats[]> => {
        return this.lastStats = await advInfoApi.getLast30Stats()
    }

    getCurrentMonthStats = async (): Promise<AdvStats> => {
        return await advInfoApi.getCurrentMonthStats()
    }

    getCurrentMonthPrice = async (): Promise<number> => {
        return await advInfoApi.getCurrentMonthPrice()
    }

    countAdvInCurrentMonth = async (): Promise<number> => {
        return await advInfoApi.countAdvInCurrentMonth()
    }

    getTop10Adv = async (): Promise<AdvInfo[]> => {
        return this.top10Adv = await advInfoApi.getTop10Adv()
    }

    saveAdvInfo = async (advInfoList: AdvInfo) => {
        return advInfoApi.postAdvInfo(advInfoList)
    }

    removeAdvInfo = async (advInfoList: AdvInfo) => {
        return advInfoApi.deleteAdvInfo(advInfoList)
    }

    createSnapshot = async (advInfoList: AdvInfo) => {
        return advInfoApi.createSnapshot(advInfoList)
    }

    findDailyStats = async (advInfoId: any) : Promise<DailyStats[]> => {
        return advInfoApi.findAdvDailyStats(advInfoId)
    }

    saveDailyStats = async (advInfoId: any, stats: DailyStats) : Promise<void> => {
        if (stats.isNew) {
            stats.id = undefined
        }
        return advInfoApi.saveAdvDailyStats(advInfoId, stats)
    }
}

export const advInfoStore: AdvInfoStore = new AdvInfoStore()


class AdvInfoApi {
    async getAdv(advId: string): Promise<AdvInfo> {
        let response = await axios.get(`/api/adv/${advId}`);
        return response.data
    }

    async getAdvInfoList(): Promise<AdvInfo[]> {
        let response = await axios.get("/api/adv");
        return response.data
    }

    async getTodayStats(): Promise<TodayStats> {
        let response = await axios.get("/api/adv/todayStats");
        return response.data
    }

    async getLast30Stats(): Promise<AdvStats[]> {
        let response = await axios.get("/api/adv/stats/last/30");
        return response.data
    }

    async countAdvInCurrentMonth(): Promise<number> {
        let response = await axios.get("/api/adv/count/current");
        return response.data
    }

    async getCurrentMonthStats(): Promise<AdvStats> {
        let response = await axios.get("/api/adv/stats/month/current");
        return response.data
    }

    async getCurrentMonthPrice(): Promise<number> {
        let response = await axios.get("/api/adv/price/month/current");
        return response.data
    }

    async getTop10Adv(): Promise<AdvInfo[]> {
        let response = await axios.get("/api/adv/top/10");
        return response.data
    }

    async postAdvInfo(advInfoList: AdvInfo): Promise<AdvInfo> {
        let response = await axios.post("/api/adv", advInfoList);
        return response.data
    }

    async deleteAdvInfo(advInfo: AdvInfo): Promise<AdvInfo> {
        let response = await axios.delete(`/api/adv/${advInfo.id}`);
        return response.data
    }

    async createSnapshot(advInfo: AdvInfo): Promise<AdvInfo> {
        let response = await axios.post(`/api/adv/${advInfo.id}/snapshot`);
        return response.data
    }

    async findAdvDailyStats(advInfoId: any): Promise<DailyStats[]> {
        let response = await axios.get(`/api/adv/${advInfoId}/stats`);
        return response.data
    }

    async saveAdvDailyStats(advInfoId: any, stats: DailyStats): Promise<void> {
        let response = await axios.post(`/api/adv/${advInfoId}/stats`, stats);
        return response.data
    }
}

export const advInfoApi: AdvInfoApi = new AdvInfoApi()

export type AdvInfo = {
    id: number | undefined;
    url: string;
    type: string;
    updateDate: string;
    viewCount: number;
    likeCount: number;
    commentCount: number;
    meta: AdvMeta;
    products: Product[];
    productsList: number[];
}

export type AdvMeta = {
    name: string,
    author: string,
    create_date: string,
}

export type TodayStats = {
    viewCount: number;
    todayViewCount: number;
    todayLikeCount: number;
    todayCommentCount: number;
}
export type DailyStats = {

    id: number | undefined;
    snapshotDate: string;
    viewCount: number;
    likeCount: number;
    commentCount: number;
    isNew: boolean;
}

export type AdvStats = {
    snapshotDate: Date;
    views: number;
    likes: number;
    comments: number;
}
