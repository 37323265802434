import React, {FC, useEffect, useRef, useState} from "react";
import {observer} from "mobx-react";
import Row from "antd/es/grid/row";
import Col from "antd/es/grid/col";
import {Button} from "../components/Button";
import {Form, Input, InputRef, PageHeader, Popconfirm, Select, Space, Statistic, Table, Tag, Typography} from "antd";
import {ProfitStatistic} from "../components/ProfitStatistic";
import {toJS} from "mobx";
import {FormDrawer} from "../components/FormDrawerComponent";
import {AdvInfo, advInfoStore, DailyStats} from "../store/AdvInfoStore";
import {advSnapshotStore} from "../store/AdvSnapshotStore";
import {ColumnsType} from "antd/es/table";
import {Product, productStore} from "../store/ProductStore";
import {DeleteOutlined, EyeOutlined} from "@ant-design/icons";
import {Link as RouterLink, useNavigate} from "react-router-dom";
import {EditableProTable, ProColumns} from "@ant-design/pro-components";


const {Link} = Typography;

export function truncate(input: string | undefined = "", count: number) {
    if (input.length > count) {
        return input.substring(0, count) + '...';
    }
    return input;
}


export const IndexPage: FC = observer(() => {

    const columns: ProColumns<AdvInfo>[] = [
        {title: 'Автор', dataIndex: ['meta', 'author'], valueType: "text", sorter: (a, b) => a.meta.author.length - b.meta.author.length},
        {title: 'Имя', dataIndex: ['meta', 'name'], valueType: "text", render: (_, info) => truncate(info.meta.name, 50)},
        {title: 'Ссылка', dataIndex: 'url', valueType: "text", render: (_, info) => <Link href={info.url}>{truncate(info.url, 50)}</Link>},
        {
            title: 'Тип', dataIndex: 'type', valueType: "select", request: async () => {
                return [
                    {value: "YOUTUBE", label: "YOUTUBE"},
                    {value: "SHORTS", label: "SHORTS"},
                    {value: "REELS", label: "REELS"},
                    {value: "TIKTOK", label: "TIKTOK"},
                ]
            }
        },
        {title: 'Дата создания', dataIndex: ['meta', 'create_date'], valueType: 'date', fieldProps: {format: "DD-MM-YYYY"}},
        {
            title: 'Продукты', dataIndex: 'products',
            renderFormItem: (_, test) => {
                console.log(_, test)
                return <ProductSelect/>
            },
            // valueType: "select", fieldProps: {
            //     mode: 'tags'
            // },
            // request: async (tet, zet) => productStore.productList.map(value => {
            //     let result = {value: value.id, label: value.name}
            //     console.log(tet, zet, result)
            //     return result
            // }),
            sorter: (a, b) => a.products.length - b.products.length,
            render: (text, {products}) => products.map(({name}) => <Tag>{name}</Tag>)
        },
        {title: 'Просмотры', dataIndex: 'viewCount', valueType: "digit"},
        // {title: '', dataIndex: 'link', render: (_, record) => <RouterLink  to={`adv/${record.id}`}>Перейти</RouterLink>},
        {
            title: 'Действия',
            valueType: 'option',
            width: 250,
            render: (text, record, _, action) => [
                <a
                    key="editable"
                    onClick={() => {
                        action?.startEditable?.(record.id || 0);
                    }}
                >
                    Редактировать
                </a>,
            ],
        }
    ];

    useEffect(() => {
        Promise.all([
            advInfoStore.getTodayStats(),
            advInfoStore.fetchAdvInfoList(),
            productStore.fetchProductList(),
        ])
    }, [])

    const createNewSnapshot = () => {
        return advSnapshotStore.updateSnapshots()
    }

    const sendTelegramStats = () => {
        return advSnapshotStore.sendTelegramStats()
    }

    const navigate = useNavigate();

    return (
        <>
            <PageHeader
                title="Аналитика роликов"
                ghost={false}
                extra={[
                    <Button key="3" onClick={sendTelegramStats}>
                        Телега
                    </Button>,
                    <Button key="2" onClick={createNewSnapshot}>
                        Обновить
                    </Button>
                ]}
            >
                <Row>
                    <ProfitStatistic
                        title="Кол-во"
                        precision={0}
                        value={advSnapshotStore.advSnapshots.length}
                        style={{
                            margin: '0 32px',
                        }}
                    />
                    <Statistic
                        title="Просмотры"
                        precision={0}
                        value={advInfoStore.todayStats.viewCount}
                    />
                    <Statistic title="Прирост за сутки" precision={0}
                               value={advInfoStore.todayStats.todayViewCount}
                               style={{
                                   margin: '0 32px',
                               }}/>
                </Row>
            </PageHeader>
            <Row className="content-container">
                <Col span={24}>
                    <EditableProTable<AdvInfo>
                        rowKey="id"
                        scroll={{
                            x: 960,
                        }}
                        recordCreatorProps={{
                            position: 'top',
                            creatorButtonText: <span>Добавить запись</span>,
                            record: () => {
                                let date: string = new Date().toISOString().slice(0, 10)
                                return {
                                    id: Math.random(),
                                    viewCount: 0,
                                    likeCount: 0,
                                    commentCount: 0,
                                    type: 'SHORTS',
                                    meta: {
                                        create_date: date
                                    }
                                } as AdvInfo;
                            },
                        }}
                        onRow={data => {
                            return {
                                onDoubleClick: event => navigate(`/adv/${data.id}`)
                            }
                        }}

                        loading={advInfoStore.loading}
                        columns={columns}
                        request={async () => ({
                            data: advInfoStore.advInfoList,
                            total: advInfoStore.advInfoList.length,
                            success: true,
                        })}
                        cardBordered={false}
                        value={advInfoStore.advInfoList}
                        onChange={data => advInfoStore.fetchAdvInfoList()}
                        editable={{
                            type: 'single',
                            onSave: async (rowKey, data, row) => {
                                debugger;
                                console.log(rowKey, data, row);
                                if (data && data.id && data.id < 1) {
                                    data.id = undefined
                                }
                                data.meta.create_date = data.meta.create_date.slice(0, 10)
                                await advInfoStore.saveAdvInfo(data)
                                console.log("Saved");
                            },
                        }}
                    />
                </Col>
                {/*<Col span={24}>*/}
                {/*    <Table<AdvInfo> rowKey="id"*/}
                {/*                    pagination={false}*/}
                {/*                    onRow={data => {*/}
                {/*                        return {*/}
                {/*                            onDoubleClick: event => addNewVideo(data)*/}
                {/*                        }*/}
                {/*                    }}*/}
                {/*                    dataSource={advInfoStore.advInfoList}*/}
                {/*                    columns={columns}/>*/}
                {/*</Col>*/}
                {/*<Col xs={{offset: 4, span: 16}} sm={{offset: 2, span: 20}}>*/}
                {/*    <Space direction={"vertical"}>*/}
                {/*        <List*/}
                {/*            grid={{ gutter: 16, column: 2 , xs: 1, sm: 1, md: 2}}*/}
                {/*            dataSource={futuresStore.actualPositions}*/}
                {/*            renderItem={(item: PositionRisk) => (*/}
                {/*                <List.Item>*/}
                {/*                    <FuturesCard item={item}/>*/}
                {/*                </List.Item>*/}
                {/*            )}*/}
                {/*        />*/}
                {/*    </Space>*/}
                {/*</Col>*/}
            </Row>
        </>
    )
})

const ProductSelect: React.FC<{
    value?: Product[];
    onChange?: (
        value: Product[],
    ) => void;
}> = ({value, onChange}) => {
    return (
        <Select<number[]>
            mode="multiple"
            filterOption={(input, option) =>
                (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
            }
            defaultValue={value?.map(value1 => value1.id)}
            onChange={selectedProducts => {
                const products: Product[] = productStore.productList.filter(product => selectedProducts.find(value => value == product.id))
                if (onChange) onChange(products)
            }}
            style={{width: '100%'}}>
            {productStore.productList.map(value => (
                <Select.Option value={value.id}>{value.name}</Select.Option>
            ))}
        </Select>
    );
};