import React from "react";
import {Dispatcher} from "flux";
import {Drawer} from "antd";
import Space from "antd/es/space";
import {Button} from "./Button";
import {DrawerProps} from "antd/lib/drawer";

let FormDialogDispatcher = new Dispatcher<FormDrawerType>();

export type FormDrawerType = {
    title?: string,
    wide?: any,
    onSave?: Function,
    form?: any,
    visible?: boolean
} & DrawerProps

export const FormDrawer = {

    open: (config: FormDrawerType) => {
        FormDialogDispatcher.dispatch({
            visible: true,
            ...config
        });
    },

    close: () => {
        return new Promise(resolve => {
            FormDialogDispatcher.dispatch({
                visible: false
            });
        });
    }
};


export class FormDrawerComponent extends React.Component<any, FormDrawerType> {

    componentDidMount() {
        FormDialogDispatcher.register((payload: FormDrawerType) => {
            this.setState( {...payload});
        });
    }

    async componentWillUnmount() {
        await FormDrawer.close();
    }

    render() {
        if (this.state == null) {
            return <></>
        }
        const {form, onSave, ...state} = this.state;
        return (
            <Drawer
                destroyOnClose={true}
                extra={
                    <Space>
                        <Button onClick={event => FormDrawer.close()}>Отмена</Button>
                        <Button onClick={async event => {
                            if (onSave) {
                                await onSave()
                            }
                            await FormDrawer.close()
                        }} type="primary">
                            Сохранить
                        </Button>
                    </Space>
                }
                {...state}
            >
                {form}
            </Drawer>
        );
    }
}
