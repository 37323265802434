import React, {useEffect, useState} from "react";
import {ColumnsType} from "antd/es/table";
import {Form, Input, List, PageHeader, Popconfirm, Select, Statistic, Table} from "antd";
import {Product, productStore} from "../store/ProductStore";
import {Button} from "../components/Button";
import Row from "antd/es/grid/row";
import {ProfitStatistic} from "../components/ProfitStatistic";
import Col from "antd/es/grid/col";
import {observer} from "mobx-react";
import {FormDrawer} from "../components/FormDrawerComponent";


export const ProductPage = observer(() => {
    const [form] = Form.useForm<Product>();

    const columns: ColumnsType<Product> = [
        {title: 'Продукт', dataIndex: 'name'},
        {title: 'Производитель', dataIndex: 'company'},
        {title: 'Ссылка', dataIndex: 'url'},
    ];


    useEffect(() => {
        productStore.fetchProductList()
    }, []);


    const addProduct = () => {

        FormDrawer.open({
            title: "Добавить продукт",
            onSave: async () => {
                const Product = form.getFieldsValue();
                await productStore.saveProduct(Product)
            },
            form: (
                <Form layout="vertical" form={form}>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="name"
                                label="Продукт"
                                rules={[{required: true, message: 'Укажите имя продукта'}]}
                            >
                                <Input placeholder="Укажите имя продукта"/>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="company"
                                label="Производитель"
                                rules={[{required: true, message: 'Укажите название производителя'}]}
                            >
                                <Input placeholder="Укажите название производителя"/>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="url"
                                label="Ссылка на товар"
                            >
                                <Input placeholder="Укажите ссылку на товар производителя"/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            )
        })
    }


    return (
        <>
            <PageHeader
                title="База продуктов для рекламы"
                ghost={false}
                extra={[
                    <Button key="2">
                        Обновить
                    </Button>,
                    <Button key="1" type="primary" onClick={addProduct}>
                        Добавить
                    </Button>,
                ]}
            >
                <Row>
                    <ProfitStatistic
                        title="Кол-во"
                        precision={0}
                        value={productStore.productList.length}
                        style={{
                            margin: '0 32px',
                        }}
                    />
                </Row>
            </PageHeader>
            <Row className="content-container">
                <Col span={24}>
                    <Table<Product> rowKey="id"
                                   loading={productStore.loading}
                                   dataSource={productStore.productList}
                                   columns={columns}/>
                </Col>
            </Row>
        </>

    );
});