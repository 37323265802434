import {makeAutoObservable} from "mobx";
import axios from "axios";

class AuthorStore {
    loading: boolean = false;
    authorList: Author[] = [];

    constructor() {
        makeAutoObservable(this)
    }

    fetchAuthorList = async (): Promise<Author[]> => {
        try {
            this.loading = true
            return this.authorList = await authorApi.getAuthors()
        } finally {
            this.loading = false
        }
    }

    saveAuthor = async (author: Author): Promise<Author> => {
        let savedAuthor = await authorApi.saveAuthor(author)
        await this.fetchAuthorList()
        return savedAuthor;
    }

    deleteAuthor = async (author: Author): Promise<void> => {
        await authorApi.deleteAuthor(author)
        await this.fetchAuthorList()
    }

}

export const authorStore: AuthorStore = new AuthorStore()

class AuthorApi {
    async getAuthors(): Promise<Author[]> {
        let response = await axios.get("/api/author/");
        return response.data
    }

    async saveAuthor(author: Author): Promise<Author> {
        let response = await axios.post("/api/author/", author);
        return response.data
    }

    async deleteAuthor(author: Author): Promise<Author> {
        let response = await axios.delete("/api/author/" + author.id);
        return response.data
    }
}

export const authorApi: AuthorApi = new AuthorApi()

export type Author = {
    id: number;
    name: string;
    socialProfiles: SocialProfile[]
}

export type SocialProfile = {
    id: number;
    url: string;
    type: string;
}
