import React from "react";
import Layout from "antd/es/layout";
import {Link, useNavigate} from "react-router-dom";
import logo from './logo.svg';
import user from './user.svg';
import Dropdown from "antd/es/dropdown";
import Space from "antd/es/space";
import Menu, {MenuProps} from "antd/es/menu";
import UserOutlined from "@ant-design/icons/UserOutlined";
import QuestionCircleOutlined from "@ant-design/icons/QuestionCircleOutlined";
import {Button} from "../components/Button";
import {authStore} from "../store/AuthStore";
import {DashboardOutlined, DropboxOutlined, TeamOutlined, YoutubeOutlined} from "@ant-design/icons";

const {Header, Content, Footer} = Layout;

export const BasicLayout = ({children}: any) => {
    return (
        <Layout className="layouts-basic-layout-container" style={{height: '100vh'}}>
            <HeaderBar/>
            <Layout>
                {/*<SiderMenu/>*/}
                <Content>{children}</Content>
            </Layout>
            <Footer>Footer</Footer>
        </Layout>
    )
}

const HeaderBar = () => {
    const items: MenuProps['items'] = [
        {
            label: 'Аналитика',
            key: 'dashboard',
            icon: <DashboardOutlined />,
        },
        {
            label: 'Авторы',
            key: 'author',
            icon: <TeamOutlined />,
        },
        {
            label: 'Продукты',
            key: 'product',
            icon: <DropboxOutlined />,
        },
        {
            label: 'Статистика Youtube',
            key: 'stats/youtube',
            icon: <YoutubeOutlined />,
        },
    ];

    const navigate = useNavigate();

    return (
        <Header style={{padding: "0 24px", display: "flex"}} className="header-dark">
            <div className="header-logo">
                <Link to={'/'}>
                    <img src={logo} alt="logo"/>
                    <h1>Adv Monitor <span style={{fontSize: '9px', marginLeft: '-2px'}}>from Braidner</span></h1>
                </Link>
            </div>
            <div style={{flex: "1 1 0%"}}>
                <Menu theme="dark" mode="horizontal" items={items} onClick={(e:any) => {
                    navigate(`/${e.key}`)
                }}/>
            </div>
            <Space>
                <Dropdown className="header-right-menu" overlay={(
                    <Menu style={{width: '150px'}}>
                        <Menu.Item icon={<UserOutlined/>} key="logout">
                            Выйти
                        </Menu.Item>
                    </Menu>
                )}>
                    <Space>
                        <img src={user} alt="user"/>
                        <span className="anticon">{authStore.currentUser?.username}</span>
                    </Space>
                </Dropdown>
                <Button type={'text'} icon={<QuestionCircleOutlined/>}/>
            </Space>
        </Header>
    )
}
