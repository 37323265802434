import React, {useEffect, useState} from "react";
import {ColumnsType} from "antd/es/table";
import {Form, Input, List, PageHeader, Popconfirm, Select, Statistic, Table} from "antd";
import {Author, authorStore} from "../store/AuthorStore";
import {Button} from "../components/Button";
import Row from "antd/es/grid/row";
import {ProfitStatistic} from "../components/ProfitStatistic";
import Col from "antd/es/grid/col";
import {observer} from "mobx-react";
import {FormDrawer} from "../components/FormDrawerComponent";
import {AdvInfo, advInfoStore} from "../store/AdvInfoStore";
import {DeleteOutlined, MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import Card from "antd/es/card/Card";


export const AuthorPage = observer(() => {
    const [form] = Form.useForm<Author>();

    const columns: ColumnsType<Author> = [
        {title: 'Автор', dataIndex: 'name'}
    ];


    useEffect(() => {
        authorStore.fetchAuthorList()
    }, []);


    const addAuthor = () => {

        FormDrawer.open({
            title: "Добавить автора",
            onSave: async () => {
                const author = form.getFieldsValue();
                await authorStore.saveAuthor(author)
            },
            form: (
                <Form layout="vertical" form={form}>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="name"
                                label="Имя автора"
                                rules={[{required: true, message: 'Укажите имя автора'}]}
                            >
                                <Input placeholder="Укажите имя автора"/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.List
                                name="socialProfiles"
                                // label="Ссылка на соц сеть"
                            >
                                {(fields, {add, remove}) => (
                                    <>
                                        {fields.map((field, index) => (
                                            <Form.Item
                                                label={index === 0 ? 'Соц Сеть' : ''}
                                                required={false}
                                                key={field.key}
                                            >
                                                <Form.Item
                                                    name={[field.name, 'url']}
                                                    validateTrigger={['onChange', 'onBlur']}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            whitespace: true,
                                                            message: "Добавить ссылку на соц сеть",
                                                        },
                                                    ]}
                                                    noStyle
                                                >
                                                    <Input placeholder="Социальная сеть" style={{width: '60%'}}/>
                                                </Form.Item>
                                                <Form.Item
                                                    name={[field.name, 'type']}
                                                    rules={[{required: true, message: 'Укажите площадку'}]}
                                                    noStyle
                                                >
                                                    <Select
                                                        style={{width: '100%'}}
                                                    >
                                                        <Select.Option value="YOUTUBE">Youtube</Select.Option>
                                                        <Select.Option value="INSTAGRAM">Instagram</Select.Option>
                                                    </Select>
                                                </Form.Item>
                                                <MinusCircleOutlined
                                                    className="dynamic-delete-button"
                                                    onClick={() => remove(field.name)}
                                                />
                                            </Form.Item>
                                        ))}
                                        <Form.Item>
                                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined/>}>
                                                Добавить Соц Сеть
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </Col>

                    </Row>
                </Form>
            )
        })
    }


    return (
        <>
            <PageHeader
                title="База авторов"
                ghost={false}
                extra={[
                    <Button key="2">
                        Обновить
                    </Button>,
                    <Button key="1" type="primary" onClick={addAuthor}>
                        Добавить
                    </Button>,
                ]}
            >
                <Row>
                    <ProfitStatistic
                        title="Кол-во"
                        precision={0}
                        value={authorStore.authorList.length}
                        style={{
                            margin: '0 32px',
                        }}
                    />
                </Row>
            </PageHeader>
            <Row className="content-container">
                <Col span={12}>
                    <Card title={"Авторы"}>
                        <List
                            itemLayout="vertical"
                            pagination={false}
                            dataSource={authorStore.authorList}
                            renderItem={(item: Author) => (
                                <List.Item key={item.id} actions={[
                                    <Button key="delete_author"
                                            onClick={async () => authorStore.deleteAuthor(item)}
                                            icon={<DeleteOutlined/>}/>
                                ]}>
                                    <List.Item.Meta
                                        title={item.name}
                                        description={"Заметки по автору: " + item.socialProfiles?.length}
                                    />
                                    {item.socialProfiles.map(item => {
                                        return <div>{item.type} | {item.url}</div>
                                    })}
                                </List.Item>
                            )}
                        />
                    </Card>
                </Col>

                <Col span={24}>
                    <Table<Author> rowKey="id"
                                   loading={authorStore.loading}
                                   dataSource={authorStore.authorList}
                                   columns={columns}/>
                </Col>
            </Row>
        </>

    );
});